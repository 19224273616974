import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import logo from '../logo_polivoz.png'
import CheckoutForm from '../CheckoutForm'
export default function Home() {
    return (
        <Container>
            <Box p={5}></Box>
            {/* <img style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '10%'
            }} src={logo} alt='polivoz' /> */}
            <Typography align='center' variant='h1'><strong>Polivoz</strong></Typography>
            <Typography align='center' color='grey' variant='h2'><strong>Mejorando la seguridad</strong></Typography>
            <Typography align='center' color='grey' variant='h2'><strong>en todas partes</strong></Typography>
           <Container maxWidth="xs">
            <Box p={2}></Box>
           <CheckoutForm />
           </Container>
            
        </Container>
    )
}