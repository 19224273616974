import React, { useState } from "react";

import {
  CardElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

export default function CheckoutForm() {

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);


  const handleSubmit2 = async () => {
    setLoading(true)
    if (elements == null) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert(submitError.message);
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      amount: 100, //cents
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const res = await fetch('https://widgets-hxhpgsxs4q-uc.a.run.app/checkout', requestOptions);
    const {client_secret: clientSecret} = await res.json();
    console.log(clientSecret)
    const { error } = stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://polivoz.com/',
      },
    });
    if (error) {
      alert(error.message);
      setLoading(false)
    }else{
      setLoading(false)
      alert("Felicidades tu pago ha sido realizado con éxito")
    }
  };
  console.log(!stripe || loading);

  return (
    <Card variant="outlined">
      <CardContent>
        <img src="https://stripe-camo.global.ssl.fastly.net/fd0c99505dbb058d8cd72c0427994cda0cd7045c7df6004c6f7ae882c4d514fa/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a644638785231497a576e6c46576b64566454686d5633566b66475a735833526c633352665555523255574530563249344d7a4d32545464345454677a5433424c626c6c6130306c487379454b4655" alt="" width="100%" />
        <Typography variant="h5" component="div">
          Polivoz Premiun
        </Typography>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          Precio
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>1,00$</Typography>
        <Box py={5}>
          <PaymentElement />
        </Box>
        <Button onClick={() => handleSubmit2()} variant="contained" fullWidth disabled={!stripe || loading}>
          {loading ? "Cargando..." : "Comprar"}
        </Button>
      </CardContent>
    </Card>
  );
};